declare global {
    interface Window {
        Locale: string,
        dataLayer: Array<object>,
        Config: object,
        collector: any,
        Validator: any,
        en_EN: any,
        de_DE: any,
        nl_NL: any,
        sv_SV: any,
        BlazeSlider: any,
    }
}

/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import ServiceContainer from '@flashpointbv/solar-service-container';
import Environment from '@flashpointbv/solar-ui-environment';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider, UICoreFormValidator} from '@flashpointbv/solar-ui-core';
import * as VueGoogleMaps from 'vue2-google-maps'
import PortalVue from 'portal-vue';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';
import {SolarEmailSimilarity} from '../../../../../vendor/happy-horizon/solar-email-similarity/resources/resources/assets/js';
import {Validator, enLang as en, nlLang as nl, deLang as de, createLang} from '@upjs/facile-validator';
import BlazeSlider from '@flashpointbv/blaze-slider/dist/blaze-slider.esm';
import UniqueId from 'vue-unique-id';
import 'lazysizes';
import filters from '../../default/js/filters';

window.BlazeSlider = BlazeSlider;
window.Validator = Validator;
window.en_EN = en;
window.de_DE = de;
window.nl_NL = nl;
window.sv_SV = createLang({
    ...en,
    required: 'Var vänlig fyll i det här fältet',
});

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
new UICoreFormValidator();

Vue.use(VueI18n);
Vue.use(VueLazyload, {preLoad: 4});
Vue.use(Vuex);
Vue.use(PortalVue);
Vue.use(UniqueId);
Vue.use(filters);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB5jWHIDK98UE-l02jjMuOObQ8OP6Zi6o4',
        libraries: 'places',
    }
});

SolarDataLayer.init({
    'item_variant': 'color',
    'item_id': 'item_id',
});

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substr(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {CatalogProduct, CatalogGroupedProduct} from '@flashpointbv/solar-ui-catalog/dist/index';
import CatalogCategory from '../../default/js/stores/modules/catalog/category';
import CatalogProductView from '../../default/js/stores/modules/catalog/product';
import CustomerWishlist from '../../default/js/stores/modules/customer/wishlist';
import CatalogConfigurableProduct from '../../default/js/stores/modules/catalog/product/configurable';
import InlineFilters from '../../default/js/stores/modules/catalog/category/inline-filters';
import CatalogProductPersonalisation from '../../default/js/stores/modules/catalog/product/personalisation';
import CatalogBundleProduct from '../../default/js/stores/modules/catalog/product/bundle';
import CatalogProductTeamWearView from '../../default/js/stores/modules/catalog/product/team-wear';

import CheckoutGlobal from '../../default/js/stores/modules/checkout/global';
import CheckoutCheckout from '../../default/js/stores/modules/checkout';
import CheckoutDHLPickupPoints from '../../default/js/stores/modules/checkout/shipping/dhl';
import CheckoutPostNLPickupPoints from '../../default/js/stores/modules/checkout/shipping/postnl';
import CheckoutFreeArticle from '../../default/js/stores/modules/checkout/free-article/free-article';
import CheckoutCart from '../../default/js/stores/modules/checkout/cart';
import CheckoutShipping from '../../default/js/stores/modules/checkout/shipping';
import CheckoutPayment from '../../default/js/stores/modules/checkout/payment';
import CheckoutTotals from '../../default/js/stores/modules/checkout/totals';
import Returns from '../../default/js/stores/modules/returns';
import CatalogProductCompare from '../../default/js/stores/modules/catalog/product/compare';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('CatalogCategory', CatalogCategory);
store.registerModule('InlineFilters', InlineFilters);
store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);
store.registerModule('CatalogProductView', CatalogProductView);
store.registerModule('CatalogProductPersonalisation', CatalogProductPersonalisation);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutCart', CheckoutCart);
store.registerModule('CheckoutCheckout', CheckoutCheckout);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CustomerWishlist', CustomerWishlist);
store.registerModule('CheckoutFreeArticle', CheckoutFreeArticle);
store.registerModule('CheckoutDHLPickupPoints', CheckoutDHLPickupPoints);
store.registerModule('CheckoutPostNLPickupPoints', CheckoutPostNLPickupPoints);
store.registerModule('CatalogProductTeamWearView', CatalogProductTeamWearView)
store.registerModule('Returns', Returns);
store.registerModule('CatalogProductCompare', CatalogProductCompare);

/**
 * Require application scripts.
 */
require('../../default/js/prototypes');
require('../../default/js/core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {key: 'search-form', concrete: require('../../default/js/components/SearchForm.vue').default},
    {key: 'autocomplete-list-item', concrete: () => import('../../default/js/components/search/AutocompleteListItem.vue')},
    {key: 'header-cart', concrete: () => import('../../default/js/components/HeaderCart.vue')},
    {key: 'checkout-cart-header-mini-cart', concrete: () => import('../../default/js/components/checkout/cart/header/MiniCart.vue')},
    {key: 'checkout-cart-header-mini-cart-item', concrete: () => import('../../default/js/components/checkout/cart/header/MiniCartItem.vue')},
    {key: 'header-wishlist', concrete: require('../../default/js/components/HeaderWishlist.vue').default},
    {key: 'off-canvas', concrete: () => import('../../default/js/components/OffCanvas.vue')},
    {key: 'footer-subscribe', concrete: () => import('../../default/js/components/FooterSubscribe.vue')},
    {key: 'catalog-search-view', concrete: () => import('../../default/js/views/catalog/CatalogSearchView.vue')},
    {key: 'toggle-wishlist', concrete: () => import('../../default/js/components/catalog/product/ToggleWishlist.vue')},
    {key: 'catalog-product-price', concrete: () => import('../../default/js/components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-size-chart', concrete: () => import('../../default/js/components/catalog/product/ProductSizeChart.vue')},
    {key: 'catalog-product-team-wear-index', concrete: () => import('../../default/js/components/catalog/product/team-wear/Index.vue')},
    {key: 'catalog-product-configuration', concrete: () => import('../../default/js/components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-simple', concrete: () => import('../../default/js/components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('../../default/js/components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatch.vue')},
    {key: 'catalog-product-configurable-swatch-item', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatchItem.vue')},
    {key: 'catalog-product-bundle', concrete: () => import('../../default/js/components/catalog/product/bundle/ProductTypeBundle.vue')},
    {key: 'catalog-product-grouped', concrete: () => import('../../default/js/components/catalog/product/grouped/ProductTypeGrouped.vue')},
    {key: 'catalog-product-grouped-product', concrete: () => import('../../default/js/components/catalog/product/grouped/ProductTypeGroupedProduct.vue')},
    {key: 'catalog-product-off-canvas', concrete: () => import('../../default/js/components/catalog/product/OffCanvas.vue')},
    {key: 'catalog-product-off-canvas-products', concrete: () => import('../../default/js/components/catalog/product/OffCanvasProducts.vue')},
    {key: 'catalog-product-review-list', concrete: () => import('../../default/js/components/catalog/product/ProductReviewList.vue')},
    {key: 'catalog-product-review-list-item', concrete: () => import('../../default/js/components/catalog/product/review/ProductReviewListItem.vue')},
    {key: 'catalog-product-stickers', concrete: () => import('../../default/js/components/catalog/ProductStickers.vue')},
    {key: 'catalog-product-label', concrete: () => import('../../default/js/components/catalog/product/ProductLabel.vue')},
    {key: 'catalog-product-review-form', concrete: () => import('../../default/js/components/catalog/product/ProductReviewForm.vue')},
    {key: 'catalog-product-product-row', concrete: () => import('../../default/js/components/catalog/product/ProductProductRow.vue')},
    {key: 'catalog-product-recent', concrete: () => import('../../default/js/components/catalog/product/ProductRecentList.vue')},
    {key: 'catalog-product-specifications-table', concrete: () => import('../../default/js/components/catalog/product/ProductSpecificationsTable.vue')},
    {key: 'catalog-category-view', concrete: () => import('../../default/js/views/catalog/CatalogCategoryView.vue')},
    {key: 'catalog-product-list', concrete: () => import('../../default/js/components/catalog/ProductList.vue')},
    {key: 'catalog-product-list-item', concrete: () => import('../../default/js/components/catalog/ProductListItem.vue')},
    {key: 'catalog-product-list-item-compare', concrete: () => import('../../default/js/components/catalog/ProductListCompare.vue')},
    {key: 'catalog-product-list-add-to-cart', concrete: () => import('../../default/js/components/catalog/product/ProductListAddToCart.vue')},
    {key: 'catalog-product-list-colors', concrete: () => import('../../default/js/components/catalog/list/ProductListColors.vue')},
    {key: 'catalog-product-list-sizes', concrete: () => import('../../default/js/components/catalog/list/ProductListSizes.vue')},
    {key: 'catalog-product-list-price', concrete: () => import('../../default/js/components/catalog/list/ProductListPrice.vue')},
    {key: 'catalog-product-filter-list', concrete: () => import('../../default/js/components/catalog/ProductFilterList.vue')},
    {key: 'catalog-product-top-filter-list', concrete: () => import('../../default/js/components/catalog/ProductTopFilterList.vue')},
    {key: 'catalog-product-quick-buy', concrete: () => import('../../default/js/components/catalog/product-quick-buy/Index.vue')},
    {key: 'catalog-product-related-blog-post', concrete: () => import('../../default/js/components/catalog/product/RelatedBlogPost.vue')},
    {key: 'catalog-category-mobile-sub-category-list', concrete: () => import('../../default/js/components/catalog/category/MobileSubCategoryList.vue')},
    {key: 'catalog-category-mobile-sub-category-list-item', concrete: () => import('../../default/js/components/catalog/category/MobileSubCategoryListItem.vue')},
    {key: 'active-filter', concrete: () => import('../../default/js/components/catalog/filter/ActiveFilter.vue')},
    {key: 'attribute-filter', concrete: () => import('../../default/js/components/catalog/filter/AttributeFilter.vue')},
    {key: 'inline-filter', concrete: () => import('../../default/js/components/catalog/filter/InlineFilter.vue')},
    {key: 'price-filter', concrete: () => import('../../default/js/components/catalog/filter/PriceFilter.vue')},
    {key: 'swatch-filter', concrete: () => import('../../default/js/components/catalog/filter/SwatchFilter.vue')},
    {key: 'catalog-category-sub-category-list', concrete: () => import('../../default/js/components/catalog/category/SubCategoryList.vue')},
    {key: 'catalog-category-sub-category-list-item', concrete: () => import('../../default/js/components/catalog/category/SubCategoryListItem.vue')},
    {key: 'catalog-product-active-filter-list', concrete: () => import('../../default/js/components/catalog/ProductActiveFilterList.vue')},
    {key: 'catalog-product-list-toolbar', concrete: () => import('../../default/js/components/catalog/ProductListToolbar.vue')},
    {key: 'catalog-product-list-toolbar-paginator', concrete: () => import('../../default/js/components/catalog/ProductListToolbarPaginator.vue')},
    {key: 'catalog-product-list-toolbar-sort-select', concrete: () => import('../../default/js/components/catalog/ProductListToolbarSortSelect.vue')},
    {key: 'catalog-product-list-count', concrete: require('../../default/js/components/catalog/ProductListCount.vue').default},
    {key: 'checkout-coupon-form', concrete: () => import('../../default/js/components/checkout/coupon/CouponForm.vue')},
    {key: 'checkout-cart', concrete: () => import('../../default/js/components/checkout/cart/Cart.vue')},
    {key: 'checkout-cart-items-list', concrete: () => import('../../default/js/components/checkout/cart/CartItemsList.vue')},
    {key: 'checkout-cart-items-list-item', concrete: () => import('../../default/js/components/checkout/cart/CartItemsListItem.vue')},
    {key: 'checkout-cart-gift-rules-list', concrete: () => import('../../default/js/components/checkout/cart/GiftRulesList.vue')},
    {key: 'checkout-cart-gift-rules-list-item', concrete: () => import('../../default/js/components/checkout/cart/GiftRulesListItem.vue')},
    {key: 'checkout-cart-totals', concrete: () => import('../../default/js/components/checkout/cart/CartTotals.vue')},
    {key: 'checkout-cart-crosssells', concrete: () => import('../../default/js/components/checkout/cart/ProductCrosssellList.vue')},
    {key: 'checkout-checkout', concrete: () => import('../../default/js/components/checkout/checkout/Checkout.vue')},
    {key: 'checkout-error-list', concrete: () => import('../../default/js/components/checkout/checkout/ErrorList.vue')},
    {key: 'checkout-collector', concrete: () => import('../../default/js/components/checkout/collector/Checkout.vue')},
    {key: 'checkout-address-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/AddressDetail.vue')},
    {key: 'checkout-address-shipping', concrete: () => import('../../default/js/components/checkout/checkout/address/ShippingAddress.vue')},
    {key: 'checkout-address-billing', concrete: () => import('../../default/js/components/checkout/checkout/address/BillingAddress.vue')},
    {key: 'checkout-address-login', concrete: () => import('../../default/js/components/checkout/checkout/address/CheckoutLogin.vue')},
    {key: 'checkout-address-login-popup', concrete: () => import('../../default/js/components/checkout/checkout/address/CheckoutLoginPopup.vue')},
    {key: 'checkout-address-login-confirm-merge-cart-popup', concrete: () => import('../../default/js/components/checkout/checkout/address/CheckoutLoginConfirmMergeCartPopup.vue')},
    {key: 'checkout-address-form-default', concrete: () => import('../../default/js/components/checkout/checkout/address/input/template/default/AddressForm.vue')},
    {key: 'checkout-address-form-nl', concrete: () => import('../../default/js/components/checkout/checkout/address/input/template/nl_NL/AddressForm.vue')},
    {key: 'checkout-address-form-be', concrete: () => import('../../default/js/components/checkout/checkout/address/input/template/nl_BE/AddressForm.vue')},
    {key: 'checkout-address-form-se', concrete: () => import('../../default/js/components/checkout/checkout/address/input/template/sv_SE/AddressForm.vue')},
    {key: 'checkout-address-form-de', concrete: () => import('../../default/js/components/checkout/checkout/address/input/template/de_DE/AddressForm.vue')},
    {key: 'checkout-address-select', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressSelect.vue')},
    {key: 'checkout-address-option', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressOption.vue')},
    {key: 'checkout-address-autocomplete', concrete: () => import('../../default/js/components/checkout/checkout/address/input/AddressAutocomplete.vue')},
    {key: 'checkout-shipping-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/ShippingDetail.vue')},
    {key: 'checkout-shipping-methods-default', concrete: () => import('../../default/js/components/checkout/checkout/shipping/methods/Default.vue')},
    {key: 'checkout-shipping-methods-postnl-pickup-points', concrete: () => import('../../default/js/components/checkout/checkout/shipping/methods/postnl/PickupPoints.vue')},
    {key: 'checkout-shipping-methods-dhl-pickup-points', concrete: () => import('../../default/js/components/checkout/checkout/shipping/methods/dhl/PickupPoints.vue')},
    {key: 'checkout-payment-detail', concrete: () => import('../../default/js/components/checkout/checkout/step/PaymentDetail.vue')},
    {key: 'checkout-payment-methods-default', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/Default.vue')},
    {key: 'checkout-payment-methods-ideal', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/Ideal.vue')},
    {key: 'checkout-payment-methods-creditcard', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/Creditcard.vue')},
    {key: 'checkout-payment-methods-giropay', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/Giropay.vue')},
    {key: 'checkout-payment-methods-afterpay20', concrete: () => import('../../default/js/components/checkout/checkout/payment/methods/AfterPay.vue')},
    {key: 'checkout-checkout-progress-bar', concrete: () => import('../../default/js/components/checkout/checkout/ProgressBar.vue')},
    {key: 'checkout-checkout-progress-bar-node', concrete: () => import('../../default/js/components/checkout/checkout/ProgressBarNode.vue')},
    {key: 'checkout-summary', concrete: () => import('../../default/js/components/checkout/checkout/summary/Summary.vue')},
    {key: 'checkout-coupon', concrete: () => import('../../default/js/components/checkout/coupon/CouponForm.vue')},
    {key: 'checkout-register-form', concrete: () => import('../../default/js/components/checkout/checkout/customer/RegisterForm.vue')},
    {key: 'success-datalayer', concrete: () => import('../../default/js/components/checkout/SuccessDatalayer.vue')},
    {key: 'customer-wishlist-index', concrete: () => import('../../default/js/components/customer/wishlist/Index.vue')},
    {key: 'blog-product-list', concrete: () => import('../../default/js/components/blog/BlogProductList.vue')},
    {key: 'return-index', concrete: () => import('../../default/js/components/returns/Index.vue')},
    {key: 'catalog-compare-toggle', concrete: () => import('../../default/js/components/catalog/compare/Toggle.vue')},
    {key: 'catalog-compare-products', concrete: () => import('../../default/js/components/catalog/compare/Products.vue')},
    {key: 'page-builder-hotspot', concrete: () => import('../../default/js/components/hotspot/Hotspot.vue')},
]);

/**
 * Register Vue translations
 */
import nl_NL from './i18n/nl_NL';
import nl_BE from './i18n/nl_BE';
import en_GB from './i18n/en_GB';
import de_DE from './i18n/de_DE';
import sv_SE from './i18n/sv_SE';

import {localize} from 'vee-validate';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';
import vee_sv_SE from 'vee-validate/dist/locale/sv.json';

localize({vee_en_GB, vee_nl_NL, vee_de_DE, vee_sv_SE});
localize(`vee_${window.Locale === 'nl_BE' ? 'nl_NL' : window.Locale}`);

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'en_GB',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages: {nl_NL, nl_BE, en_GB, de_DE, sv_SE}
});

window.addEventListener('load', () => {
    new SolarEmailSimilarity({
        'message': i18n.t('Did you mean {email}?')
    });
});

new Vue({
    store,
    i18n,
    data: {
        locale: window.Locale
    },
    components: {
        ...ServiceContainer().getComponents()
    },
    created(): void {
        this.$store.dispatch('CheckoutGlobal/initialize', window.Config)
    }
}).$mount('#solar-app');
